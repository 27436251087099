import React from "react"
//import { Link } from "gatsby"
import Img from 'gatsby-image'
import Image from "../../image"

// s4n
// import "./../assets/sass/pages/_blog.scss"
// import axios from "axios"
import { useIntl } from "gatsby-plugin-intl"
import { Carousel } from 'react-bootstrap'
import ProductCard from './../Product/card'



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage

    const { products, limit, gridClassName } = props
    const disableHeader = props.disableHeader || false;
    const relatedProducts = products || [];

    const displayAs = props.displayAs || `grid`; // grid | carousel
    const customLastElement = props.customLastElement || null;

// console.log(relatedProducts)

    if (!relatedProducts.length) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="row product-list">
                {!disableHeader &&                             
                    <div className="col-lg-12">
                        <h2 className="mb-4">{ t({ id: "soft4net_shop_product_related_related_products" }) }</h2>
                    </div>
                }

                {`grid` === displayAs && relatedProducts.map((product, index) => {
                    if (index >= limit) {
                        return null;
                    }

                    return (
                        <React.Fragment>
                            <div key={`product-index-card-${index}`} className={gridClassName || `col-xl-3 col-lg-4 col-sm-6`}>
                                <ProductCard product={product} />
                            </div>
                        </React.Fragment>
                    )
                })}

                {`carousel` === displayAs &&
                    <Carousel
                        // interval={null}
                        // indicators={false}
                    >
                        {relatedProducts.map((product, index) => {
                            return (
                                <Carousel.Item>
                                    {/* <img
                                        className="d-block w-100"
                                        src="holder.js/800x400?text=First slide&bg=373940"
                                        alt="First slide"
                                    /> */}
                                    {/* <Img fluid={ image.fluid } alt={image.alt} /> */}
                                    {/* <Carousel.Caption>
                                        <h3>First slide label</h3>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption> */}

                                    <ProductCard product={product} />
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                }

                {customLastElement}

            </div>
        </React.Fragment>
    )
}

export default ComponentStateless